import React, {useState} from 'react'
import FirebaseAuthService from '../FirebaseAuthService';


const LoginForm = ({existingUser}) => {
	const [username, setUsername] = useState("")
	const [password, setPassword] = useState("")

	const handleSubmit = (e) => {
		e.preventDefault()

		const submit = async() => {
			try {
				await FirebaseAuthService.loginUser(username, password)
				setUsername('')
				setPassword('')
			} catch (e) {

			}
		}
		submit()
	}

	const handleLogout = () => {
		FirebaseAuthService.logoutUser()
	}

	async function handleResetPasswordEmail() {
		if(!username) {
			alert('Missing Username')
			return
		}

		try {
			await FirebaseAuthService.sendPasswordResetEmail(username)
			alert('Sent password reset email')
		} catch(e) {
			alert('Login Failed')
		}
	}


	// eslint-disable-next-line no-unused-vars
	async function handleLoginWithGoogle() {
		try {
			await FirebaseAuthService.loginWithGoogle();
		} catch(e) {
			alert('Error logging in with Google')
		}
	}

	return ( 
		<div className="login-form-container">
			{
				existingUser ? <div className="row">
					<h3>Welcome, {existingUser.email}</h3>
					<button type="button" className="primary-button" onClick={handleLogout}>Logout</button>
				</div> : 
				<form onSubmit={handleSubmit} className="login-form">
					<label className="input-label login-label">
						Username (email):
						<input 
							type="email" 
							required 
							value={username} 
							onChange={ (e) => setUsername(e.target.value) }
							className="input-text"
						/>
					</label>
					<label className="input-label login-label">
						Password:
						<input 
							type="password" 
							required 
							value={password} 
							onChange={ (e) => setPassword(e.target.value) }
							className="input-text"
						/>
					</label>
					<div className="button-box">
						<button className="primary-button" onClick={handleSubmit}>Login</button>
						<button type="button" className="primary-button" onClick={handleResetPasswordEmail}>Reset Password</button>
						{/* <button type="button" className="primary-button" onClick={handleLoginWithGoogle}>Login With Google</button> */}
					</div>
				</form>
			}
		</div>
	 );
}
 
export default LoginForm;
