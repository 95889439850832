import {useState,useEffect} from 'react'
import FirebaseAuthService from './FirebaseAuthService';
import LoginForm from './components/LoginForm'
import './App.css';
import AddEditRecipeForm from './components/AddEditRecipeForm';
import FirebaseFirestoreService from './FirebaseFirestoreService';



function App() {
  const [user, setUser] = useState(null)
  const [recipes, setRecipes] = useState([])

  useEffect(() => {
    fetchRecipes()
      .then( (fetchedRecipes) => {
        setRecipes(fetchedRecipes)
      })
      .catch( (e) => {
        console.log(e.message)
      })
  }, [user]);

  FirebaseAuthService.subscribeToAuthChanged(setUser)

  async function fetchRecipes() {
    let fetchedRecipes = [];
    try {
      const response = await FirebaseFirestoreService.readDocuments('recipes')
      const newRecipes = response.docs.map( doc => {
        const id = doc.id;
        const data = doc.data();
        data.publishDate = new Date(data?.publishDate.seconds * 1000)
        return { ...data, id}
      })

      fetchedRecipes = [...newRecipes]
    } catch(e) {
      console.log(e.message)
      throw e;
    }
    return fetchedRecipes
  }

  async function handleFetchRecipes() {
    try {
      const fetchedRecipes = await fetchRecipes()
      setRecipes(fetchedRecipes)
    } catch (error) {
      console.error(error.message)
      throw error;
    }
  }

  async function handleAddRecipe(newRecipe) {
    try {
      const response = await FirebaseFirestoreService.createDocument('recipes', newRecipe);
      alert(`success: id ${response.id}`)
      handleFetchRecipes()
    } catch (e) {
      console.log('e', e.message)
    }
  }

  function lookupCategoryLabel(categoryKey) {
    const categories = {
      breadsSandwichesAndPizza: 'Breads, Sandwiches, and Pizza',
      eggsAndBreakfast: 'Eggs & Breakfast',
      fishAndSeafood: 'Fish & Seafood',
      vegetables: 'Vegetables'
    }

    const label = categories[categoryKey];
    return label;
  }

  function formatDate(date) {
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getFullYear();

    return `${month}-${day}-${year}`
  }
  return (
    <div className="App">
      <div className="title-row">
        <h1 className="title">Firebase Recipes</h1>
        <LoginForm existingUser={user} />
      </div>
      <div className="main">
        <div className="center">
          <div className="recipe-list-box">
            { (recipes && recipes.length > 0) &&
              <div className="recipe-list">
                {recipes.map( (recipe, index) => (
                  <div className="recipe-card" key={index}>
                    <div className="recipe-name">{recipe.name}</div>
                    <div className="recipe-field">Category: { lookupCategoryLabel(recipe.category) }</div>
                    <div className="recipe-field">Publish Date: {formatDate(recipe.publishDate)}</div>
                  </div>
                ))}
              </div>
            }
          </div>
        </div>
        {user &&
          <AddEditRecipeForm handleAddRecipe={handleAddRecipe}/>
        }
      </div>
    </div>
  );
}

export default App;
